<template>
  <b-container class="mb-4">
    <b-row class="mb-5 pt-5">
      <b-col class="d-flex justify-content-between">
        <curva-title :title="$t('about.clubDetails')"/>
        <router-link :to="`/categories/all-categories?club=${$route.params.id }`">
          <b-button class="curva-icon-btn">{{ $t('about.browse') }} </b-button>
        </router-link>
      </b-col>
    </b-row>
    <b-row><b-col>
      <b-card class="details-container iq-style4">
        <b-row>
          <b-col lg="2" class="text-center">
            <img :src="details.image" style="width: 200px"/>
            <h3>{{ details.name }}</h3>
          </b-col>
          <b-col lg="10">
            <b-row>
              <b-col lg="12" style="text-align: initial">
                <h4>{{ $t('clubDetails.about', { name: details.name }) }}</h4>
                <p>{{ details.desc }}</p>
              </b-col>
              <b-col lg="12" style="text-align: initial">
                <h4>{{ $t('clubDetails.generalInfo') }}</h4>
                <b-row class="general-info mb-2">
                  <b-col lg="3">
                    <label>{{ $t('clubDetails.fullName') }}</label>
                    <p>{{ details.full_name }}</p>
                  </b-col>
                  <b-col lg="3">
                    <label>{{ $t('clubDetails.foundingDate') }}</label>
                    <p>{{ details.founding_date }}</p>
                  </b-col>
                  <b-col lg="3">
                    <label>{{ $t('clubDetails.founders') }}</label>
                    <p>{{ details.founders_name }}</p>
                  </b-col>
                  <b-col lg="3">
                    <label>{{ $t('clubDetails.league') }}</label>
                    <p>{{ details.league ? details.league.name : $t('clubDetails.noData') }}</p>
                  </b-col>
                  <b-col lg="3">
                    <label>{{ $t('clubDetails.nickname') }}</label>
                    <p>{{ details.nickname ? details.nickname : $t('clubDetails.noData') }}</p>
                  </b-col>
                  <b-col lg="3">
                    <label>{{ $t('clubDetails.homeStadium') }}</label>
                    <p>{{ details.stadium ? details.stadium.name : $t('clubDetails.noData') }}</p>
                  </b-col>
                  <b-col lg="3">
                    <label>{{ $t('clubDetails.kitSupplier') }}</label>
                    <p v-if="details.supplier">{{ details.supplier }}</p>
                  </b-col>
                  <b-col lg="3">
                    <label>{{ $t('clubDetails.kitColor') }}</label>
                    <p>{{ details.kit_color }}</p>
                  </b-col>
                </b-row>
              </b-col>
              <!-- <b-col lg="12">
                <b-row class="align-items-center">
                  <b-col lg="auto">
                    <div class="text-center d-flex"><h4 class="with-line">Domestic achievements</h4></div>
                  </b-col>
                  <b-col><span class="after-title-line" style="left: 0"></span></b-col>
                </b-row>
                <b-row>
                  <b-col lg="5" v-for="(achievement, ind) in clubDetails.domesticAchievements" :key="ind">
                    <p><i class="las la-certificate"></i> {{ achievement }}</p>
                  </b-col>
                </b-row>
              </b-col>
              <b-col lg="12">
                <b-row class="align-items-center">
                  <b-col lg="auto">
                    <div class="text-center d-flex"><h4 class="with-line">International achievements</h4></div>
                  </b-col>
                  <b-col><span class="after-title-line" style="left: 0"></span></b-col>
                </b-row>
                <b-row>
                  <b-col lg="5" v-for="(achievement, ind) in clubDetails.internationalAchievements" :key="ind">
                    <p><i class="las la-certificate"></i> {{ achievement }}</p>
                  </b-col>
                </b-row>
              </b-col>
              <b-col lg="12">
                <b-row class="align-items-center">
                  <b-col lg="auto">
                    <div class="text-center d-flex"><h4 class="with-line">Notable Players</h4></div>
                  </b-col>
                  <b-col><span class="after-title-line" style="left: 0"></span></b-col>
                </b-row>
                <b-row>
                  <b-col lg="5" v-for="(player, ind) in clubDetails.notablePlayers" :key="ind">
                    <p><i class="fas fa-user"></i> {{ player }}</p>
                  </b-col>
                </b-row>
              </b-col> -->
            </b-row>
          </b-col>
        </b-row>
      </b-card>
    </b-col></b-row>
  </b-container>
</template>

<script>
import { core } from '@/config/pluginInit'
import aboutServices from '@/modules/about/services/about.services'

export default {
  props: {
    clubDetails: {
      default () {
        return {
          name: 'Al Ahly',
          img: require('@/assets/images/curva/top-clubs/ahly.png'),
          about: 'Al Ahly is an Egyptian professional sports club located in Cairo. Founded in 1907, the club is mainly known for its football team that currently plays in the Egyptian Premier League, the top-tier league in the country, and is also one of the two founding members of the league that never suffered relegation, with the other teams being local rivals Zamalek. Al Ahly is the most successful Egyptian and African team, winning more domestic and African titles than any other club in continent. The club was also given the “African Club of the 20th Century” title by CAF in December 2000.',
          generalInfo: {
            fullName: 'Al Ahly Sports Club',
            datOfFounding: '24th april, 1907',
            founders: ['Omar Lotfy', 'Example'],
            league: 'Egyptian premier league',
            nicknames: ['The red devils'],
            homeStadium: 'Al Ahly & Al Salam Stadium',
            kitSupplier: 'umbro (since 2018)',
            kitColors: 'Red'
          },
          domesticAchievements: ['42 Egyptian Premier League titles', '37 Egypt Cup titles',
            '11 Egyptian Super Cup titles', '16 Cairo League titles', '7 Sultan Hussein Cup titles'],
          internationalAchievements: ['10 CAF Champions League titles', '1 CAF Confederation Cup title',
            '4 African Cup Winners’ Cup titles', '8 CAF Super Cup titles'],
          notablePlayers: ['Mahmoud El Khatib', 'Hossam Hassan', 'Mohamed Abou Trika', 'Essam El Hadary']
        }
      }
    }
  },
  data () {
    return {
      details: {}
      // clubDetails: {}
    }
  },
  methods: {
    getClubDetails () {
      aboutServices.getSpacificClub(this.$route.params.id).then(res => {
        this.details = res.data.data
      })
    }
  },
  mounted () {
    core.index()
  },
  created () {
    this.getClubDetails()
  }
}
</script>
